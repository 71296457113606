import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import View from './view'

const mapStateToProps = state => ({ auth: state.auth })

const enharce = compose(
  connect(mapStateToProps),
  withRouter
)

export default enharce(View)
