import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import ComponentStyled from './styled'

const RegularButton = ({ label, onClick, type, loading, disable, textUppercase }) => {
  return (
    <ComponentStyled disable={loading || disable} textUppercase={textUppercase}>
      <button type={type} onClick={loading || disable ? () => {} : onClick}>
        {label && <span>{label}</span>}
        {loading && (
          <div className='loading-wrapper'>
            <CircularProgress className='loading' color={'inherit'} size={18} />
          </div>
        )}
      </button>
    </ComponentStyled>
  )
}

RegularButton.defaultProps = {
  label: '',
  onClick: () => {},
  loading: false,
  disable: false,
  type: 'button',
  textUppercase: true,
}

RegularButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disable: PropTypes.bool,
  type: PropTypes.string,
  textUppercase: PropTypes.bool,
}

export default RegularButton
