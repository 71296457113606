import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  .login-logo {
    max-width: 175px;
    margin: 0 auto 30px auto;
    display: block;
    height: auto;
  }

  .btn-wrapper {
    text-align: center;
  }

  .fb-wrapper {
    margin-top: 50px;
    text-align: center;
  }

  .fb-title {
    text-align: center;
    margin-bottom: 15px;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.3px;
    color: ${colors.black2};
  }
`

export default ComponentStyled
