import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  .label,
  .input-error {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin-bottom: 0;
  }

  .label {
    color: ${colors.black2};
  }

  .input-error {
    color: ${colors.red};
  }

  .regular-input-wrapper {
    position: relative;

    input {
      width: 100%;
      outline: none;
      border: 0;
      border-bottom: 1px solid ${colors.greyLight};
      padding: 5px 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.3px;
      color: ${colors.black2};

      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button,
      &[type='time']::-webkit-inner-spin-button,
      &[type='time']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::-ms-clear {
        width: 0;
        height: 0;
      }

      &[type='time']::-webkit-clear-button {
        display: none;
      }

      ::placeholder {
        color: ${colors.greyLight};
      }
    }

    svg,
    img {
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 0;
      background: ${colors.white};
    }
  }

  ${props =>
    props.error === true &&
    css`
      .regular-input-wrapper {
        input {
          border-color: ${colors.red};
        }
      }
    `}

  ${props =>
    props.hasMarginBottom === true &&
    css`
      margin-bottom: 25px;
    `}
`

export default ComponentStyled
