import React, { useEffect, useState } from 'react'
import ComponentStyled from './styled'
import { ReactComponent as CookieIcon } from '../../../assets/images/cookie.svg'
import i18n from '../../../assets/i18n'
import externalRoutes from '../../../config/external-routes'
import { useCookies } from 'react-cookie'

const CookiesBanner = () => {
  const [cookies, setCookie] = useCookies(['btCookieConsent', 'btCookieAdvisement'])
  const [showAdvisement, setShowAdvisement] = useState(0)

  useEffect(() => {
    !cookies.btCookieConsent && setCookie('btCookieConsent', 'false-false', { path: '/' })
    cookies.btCookieAdvisement === '1' ? setShowAdvisement(1) : setShowAdvisement(0)
  }, [setShowAdvisement, setCookie, cookies.btCookieAdvisement, cookies.btCookieConsent])

  const setAllCookies = () => {
    const d = new Date()
    const year = d.getFullYear()
    const month = d.getMonth()
    const day = d.getDate()
    const c = new Date(year + 1, month, day)

    let cookieDomain = window.location.hostname
    cookieDomain = cookieDomain.replace('www.', '')

    setCookie('btCookieConsent', 'true-true', { path: '/', expires: c, domain: cookieDomain })
    setCookie('btCookieAdvisement', '1', { path: '/', expires: c, domain: cookieDomain })
    setShowAdvisement(1)
  }

  return (
    <ComponentStyled>
      {showAdvisement === 0 && (
        <div className='cookie-consent'>
          <div className='cookie-consent_wrap'>
            <p className='cookie-consent_container'>
              <CookieIcon className='cookies-icon' />
              <span>
                {i18n.cookieConsent.cookiesPolicyCopy}{' '}
                <a href={externalRoutes.cookies} target='_blank' rel='noopener noreferrer' className='cookies-link'>
                  {i18n.cookieConsent.cookiesPolicy}
                </a>
              </span>
            </p>
          </div>
          <div className='cookie-consent_buttons'>
            <a href={externalRoutes.cookies} target='_blank' rel='noopener noreferrer' className='cookies-link'>
              {i18n.cookieConsent.cookiesPolicy}
            </a>
            <button className='cookie-consent_btn_right' id='rcc-confirm-button' onClick={setAllCookies}>
              {i18n.cookieConsent.accept}
            </button>
          </div>
        </div>
      )}
    </ComponentStyled>
  )
}

export default CookiesBanner
