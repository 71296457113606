import styled from 'styled-components'

const ComponentStyled = styled.div`
  padding-bottom: 30px;

  .save-btn-wrapper {
    text-align: center;
  }
`

export default ComponentStyled
