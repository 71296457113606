import * as types from './types'

export const setAppInit = () => ({
  type: types.SET_AUTH_INIT,
})

export const setUser = value => ({
  type: types.SET_AUTH_USER,
  payload: value,
})

export const setToken = value => ({
  type: types.SET_AUTH_TOKEN,
  payload: value,
})

export const setFetching = isFetching => {
  return { type: types.SET_AUTH_FETCHING, payload: isFetching }
}

export const logout = () => ({
  type: types.LOGOUT,
})
