import { connect } from 'react-redux'
import View from './view'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

const mapStateToProps = state => {
  return {
    token: state.auth.token,
  }
}

const mapDispatchToProps = () => {
  return {}
}

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withRouter)

export default enhanced(View)
