import React from 'react'
import { Splash, Dashboard, ShareApp, NotFound } from '../../pages'
import routes from '../../../config/routes'
import PropTypes from 'prop-types'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { PrivateRoute } from '../../templates'
import { CookiesBanner } from '../../atoms'

class Routes extends React.PureComponent {
  static propTypes = {
    auth: PropTypes.object,
  }

  render() {
    const { auth } = this.props

    if (!auth.init) return null

    return (
      <BrowserRouter>
        <Switch>
          <Route component={ShareApp} path={routes.shareApp} />
          <PrivateRoute component={Dashboard} path={routes.dashboard} />
          <Route component={Splash} exact path={routes.home} />
          <Route component={NotFound} path={'*'} />
        </Switch>
        <CookiesBanner />
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => ({ auth: state.auth })

const enhanced = compose(connect(mapStateToProps))

export default enhanced(Routes)
