import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { authUtils } from '../../../redux/auth'
import routes from '../../../config/routes'

const PrivateRoute = ({ auth, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!auth.init || !authUtils.isLogedUser({ auth })) {
          return (
            <Redirect
              to={{
                pathname: routes.home,
                state: { from: props.location },
              }}
            />
          )
        } else {
          return <Component {...props} />
        }
      }}
    />
  )
}

PrivateRoute.propTypes = {
  auth: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  component: PropTypes.any,
}

export default PrivateRoute
