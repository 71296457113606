import React from 'react'
import { RegularButton } from '../../atoms'
import { Container } from '@material-ui/core'
import routes from '../../../config/routes'
import i18n from '../../../assets/i18n'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'

const NotFound = ({ history, token }) => (
  <ComponentStyled>
    <Container maxWidth='lg'>
      <p className='error'>404</p>
      <p className='title'>{i18n.notFound.title}</p>
      <p className='subtitle'>{i18n.notFound.subtitle}</p>
      <RegularButton onClick={() => history.push(token ? routes.dashboard : routes.home)} label={i18n.notFound.back} />
    </Container>
  </ComponentStyled>
)

NotFound.propTypes = {
  history: PropTypes.object,
  token: PropTypes.string,
}

export default NotFound
