import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  padding: 30px;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: ${colors.black2};
    text-align: center;
    margin-bottom: 20px;
  }

  .cancel {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: ${colors.black2};
    margin-bottom: 0;

    span {
      cursor: pointer;
    }
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    margin-top: 80px;
    justify-content: space-evenly;
  }
`

export default ComponentStyled
