import { connect } from 'react-redux'
import { authOperations } from '../../../redux/auth'
import View from './view'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(authOperations.logout()),
  }
}

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withRouter)

export default enhanced(View)
