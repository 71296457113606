import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  .label,
  .input-error {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin-bottom: 0;
  }

  .input-error {
    color: ${colors.red};
  }

  ${props =>
    props.hasMarginBottom === true &&
    css`
      margin-bottom: 25px;
    `}

  .react-select-container {
    .react-select__control {
      width: 100%;
      outline: none;
      border: 0;
      border-bottom: 1px solid ${colors.greyLight};
      border-radius: 0;
      background: transparent;
      min-height: inherit;
      box-shadow: none;

      ${props =>
        props.error === true &&
        css`
          border-color: ${colors.red};
        `}

      .react-select__value-container {
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.3px;
        padding: 5px 0;
        color: ${colors.black2};
      }
      .react-select__indicators {
        .react-select__indicator-separator {
          display: none;
        }
        .react-select__indicator {
          padding: 0;
          svg path {
            color: ${colors.red};
          }
        }
      }
    }

    .react-select__menu {
      .react-select__menu-list {
        .react-select__option--is-focused {
          background-color: ${colors.greyLight};
        }

        .react-select__option--is-selected,
        .react-select__option--is-focused.react-select__option--is-selected {
          background-color: ${colors.black2};
          color: ${colors.white};
        }
      }
    }
  }
`

export default ComponentStyled
