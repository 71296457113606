import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import ComponentStyled from './styled'
import { ReactComponent as FacebookIcon } from '../../../assets/images/facebook.svg'
import i18n from '../../../assets/i18n'

const FacebookButton = ({ onClick, loading, disable }) => {
  return (
    <ComponentStyled disable={loading || disable}>
      <button onClick={loading || onClick ? onClick : () => {}}>
        <span>
          <FacebookIcon className='fb-icon' />
          {i18n.facebookButton.label}
        </span>
        {loading && (
          <div className='loading-wrapper'>
            <CircularProgress className='loading' color={'inherit'} size={18} />
          </div>
        )}
      </button>
    </ComponentStyled>
  )
}

FacebookButton.defaultProps = {
  onClick: () => {},
  loading: false,
  disable: false,
}

FacebookButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disable: PropTypes.bool,
}

export default FacebookButton
