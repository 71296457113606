import styled from 'styled-components'
import colors from '../../../assets/colors'

export const ComponentStyled = styled.div`
  .cookie-consent {
    display: flex;
    align-items: center;
    background: linear-gradient(-349.38deg, rgb(179, 17, 0) 6.93%, rgb(248, 70, 110) 93.37%);
    color: white;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    bottom: 0px;
  }

  .cookie-consent_wrap {
    flex: 1 0 300px;
    margin: 15px;
  }

  .cookie-consent_container {
    color: rgb(255, 255, 255);
    margin: 0px;
    display: flex;
    align-items: center;
  }

  .cookie-consent_container a {
    color: rgb(255, 255, 255);
    text-decoration: underline;
  }

  .cookie-consent_btn_left {
    background: transparent;
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    flex: 0 0 auto;
    padding: 0px;
    margin: 10px 15px;
  }

  .cookie-consent_btn_right {
    background: transparent;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 0px;
    box-shadow: none;
    color: rgb(255, 255, 255);
    cursor: pointer;
    flex: 0 0 auto;
    padding: 5px 10px;
    margin: 10px 15px;
  }

  .cookies-link {
    color: ${colors.white};
    text-decoration: underline;
  }

  .cookies-icon {
    min-width: 30px;
    max-width: 30px;
    margin-right: 15px;
  }

  @media (max-width: 768px) {
    .cookie-consent {
      justify-content: center;
    }
    .cookie-consent_buttons {
      text-align: center;
    }
  }
`

export default ComponentStyled
