import ComponentStyled from './styled'
import React from 'react'
import { Grid } from '@material-ui/core'
import i18n from '../../../assets/i18n'
import externalRoutes from '../../../config/external-routes'

const Footer = () => {
  return (
    <ComponentStyled>
      <Grid container spacing={3} direction='row' justify='space-between' alignItems='center'>
        <Grid item xs={12}>
          <p className='content'>
            <a href={externalRoutes.termsCreators} target='_blank' rel='noopener noreferrer' className='link'>
              {i18n.footer.terms}
            </a>
            ,{' '}
            <a href={externalRoutes.legalWarning} target='_blank' rel='noopener noreferrer' className='link'>
              {i18n.footer.legalWarning}
            </a>
            ,{' '}
            <a href={externalRoutes.privacyCreators} target='_blank' rel='noopener noreferrer' className='link'>
              {i18n.footer.privacy}
            </a>
            ,{' '}
            <a href={externalRoutes.cookies} target='_blank' rel='noopener noreferrer' className='link'>
              {i18n.footer.cookies}
            </a>
          </p>
        </Grid>
      </Grid>
    </ComponentStyled>
  )
}

export default Footer
