import styled from 'styled-components'

const ComponentStyled = styled.div`
  .logo {
    max-width: 130px;
    height: auto;
    margin: ${props => (props.alignCenter ? '0 auto' : '0')};
    display: block;
    margin-bottom: 30px;
  }
`

export default ComponentStyled
