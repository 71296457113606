import { LANDING_DOMAIN } from './constants'

export default {
  cookies: `${LANDING_DOMAIN}/cookies.html`,
  privacyCreators: `${LANDING_DOMAIN}/privacy-creators.html`,
  termsCreators: `${LANDING_DOMAIN}/terms-and-conditions-creators.html`,
  privacyConsumers: `${LANDING_DOMAIN}/privacy-consumers.html`,
  termsConsumers: `${LANDING_DOMAIN}/terms-and-conditions-consumers.html`,
  legalWarning: `${LANDING_DOMAIN}/legal.html`,
}
