import * as api from '../api/'
import i18n from '../assets/i18n/'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import { authOperations } from '../redux/auth/'
import { errorOperations } from '../redux/error/'
import * as reducers from '../redux/'
import storage from 'redux-persist/lib/storage'
import { createLogger } from 'redux-logger'
import { toast } from 'react-toastify'
import { persistStore, persistReducer } from 'redux-persist'
import _ from 'lodash'

const { setError } = errorOperations

//Add all reducers to black list
const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: _.map(reducers, (_, key) => key),
}

//Add reducer-properties to white list
const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'user'],
}

const rootReducer = combineReducers({
  ...reducers,
  auth: persistReducer(authPersistConfig, reducers.auth),
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)
const loggerMiddleware = createLogger({ collapsed: true })
const thunkWithExtraArguments = thunk.withExtraArgument({ i18n, api, toast, setError })

const IS_DEV = process.env.NODE_ENV === 'development'
const IS_DEBUG = window.__REDUX_DEVTOOLS_EXTENSION__ ? true : false
let composeMiddleware = null

if (IS_DEV && IS_DEBUG) {
  composeMiddleware = compose(
    applyMiddleware(thunkWithExtraArguments, loggerMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__()
  )
} else if (IS_DEV) {
  composeMiddleware = compose(applyMiddleware(thunkWithExtraArguments, loggerMiddleware))
} else {
  composeMiddleware = compose(applyMiddleware(thunkWithExtraArguments))
}

const store = createStore(persistedReducer, composeMiddleware)
const persistor = persistStore(store, {}, () => store.dispatch(authOperations.checkSession()))

export { store, persistor }
