import React from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'
import Dialog from '@material-ui/core/Dialog'
import { RegularButton, RegularInput, RegularSelect, SingleDatePicker, FileInput } from '../../atoms'
import i18n from '../../../assets/i18n'
import { Formik } from 'formik'
import { Grid } from '@material-ui/core'
import { ReactComponent as TimerIcon } from '../../../assets/images/timer.svg'
import { VIDEO_STATUS, MAX_VIDEO_SECONDS } from '../../../config/constants'
import _ from 'lodash'
import moment from 'moment'
import { validVideoType } from '../../../utils'

const UploadVideoModal = ({ onClose, open, isFetching, onSubmit, categories }) => {
  const publishOptions = [
    { value: VIDEO_STATUS.public, label: i18n.updateVideoModal.public },
    { value: VIDEO_STATUS.programmed, label: i18n.updateVideoModal.programmed },
    { value: VIDEO_STATUS.private, label: i18n.updateVideoModal.private },
  ]

  return (
    <Dialog classes={{ paper: 'dialog-paper' }} onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
      <ComponentStyled>
        <Formik
          initialValues={{
            video: null,
            videoDuration: 0,
            title: '',
            status: null,
            publicationDate: null,
            time: null,
            category: null,
          }}
          validate={values => {
            const errors = {}

            if (!values.video) {
              errors.video = i18n.requiredField
            } else if (values.video && !validVideoType(values.video.type)) {
              errors.video = i18n.fileTypeError
            } else if (values.video && values.videoDuration > MAX_VIDEO_SECONDS) {
              errors.video = i18n.videoDurationError
            }

            if (!values.title) {
              errors.title = i18n.requiredField
            }

            if (!values.status) {
              errors.status = i18n.requiredField
            }

            if (values.status?.value === VIDEO_STATUS.programmed && !values.publicationDate) {
              errors.publicationDate = i18n.requiredField
            }

            if (values.status?.value === VIDEO_STATUS.programmed && !values.time) {
              errors.time = i18n.requiredField
            }

            if (values.status?.value === VIDEO_STATUS.programmed && values.time && values.publicationDate) {
              const today = moment().format('YYYY-MM-DD HH:mm')
              const publicationDate = moment(values.publicationDate).format('YYYY-MM-DD')
              const formatDate = moment(`${publicationDate} ${values.time}`).format('YYYY-MM-DD HH:mm')
              if (moment(formatDate).isSameOrBefore(today)) {
                errors.time = i18n.timeError
              }
            }

            if (!values.category) {
              errors.category = i18n.requiredField
            }

            return errors
          }}
          onSubmit={data => {
            data.categoryId = _.get(data, 'category.value', '')
            data = _.omit(data, ['category'])
            data.status = _.get(data, 'status.value', '')

            if (data.status === VIDEO_STATUS.public) {
              data.publicationDate = moment()
              data.status = VIDEO_STATUS.programmed
              data = _.omit(data, ['time'])
            } else if (data.status === VIDEO_STATUS.programmed) {
              const date = moment(data.publicationDate).format('YYYY-MM-DD')
              data.publicationDate = moment(`${date} ${data.time}`)
              data = _.omit(data, ['time'])
            } else if (data.status === VIDEO_STATUS.private) {
              data = _.omit(data, ['publicationDate', 'time'])
            }

            const { video, ...videoData } = data
            onSubmit(videoData, video)
          }}
        >
          {({ values, handleChange, setFieldValue, handleBlur, handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <p className='title'>{i18n.uploadVideoModal.modalTitle}</p>

              <FileInput
                handleOnChange={video => {
                  if (video) {
                    const formatFile = video
                    const mime = formatFile.type
                    const rd = new FileReader()

                    rd.onload = e => {
                      const blob = new Blob([e.target.result], { type: mime })
                      const url = URL.createObjectURL(blob)
                      const formatVideo = document.createElement('video')

                      formatVideo.preload = 'metadata'
                      formatVideo.addEventListener('loadedmetadata', () => {
                        const duration = formatVideo.duration
                        setFieldValue('video', video)
                        setFieldValue('videoDuration', duration)
                        URL.revokeObjectURL(url)
                      })
                      formatVideo.src = url
                    }
                    rd.readAsArrayBuffer(video)
                  } else {
                    setFieldValue('video', null)
                    setFieldValue('videoDuration', 0)
                  }
                }}
                label={i18n.uploadVideoModal.videoPlaceholder}
                error={errors.video && touched.video && errors.video}
              />

              <RegularInput
                handleOnChange={handleChange}
                handleOnBlur={handleBlur}
                value={values.title}
                name='title'
                label={i18n.uploadVideoModal.title}
                placeholder={i18n.uploadVideoModal.titlePlaceholder}
                error={errors.title && touched.title && errors.title}
              />

              <RegularSelect
                options={publishOptions}
                handleOnChange={status => {
                  setFieldValue('status', status)
                }}
                handleOnBlur={handleBlur}
                label={i18n.uploadVideoModal.whenPublish}
                defaultValue={{ value: '', label: i18n.uploadVideoModal.optionSelect }}
                error={errors.status && touched.status && errors.status}
              />

              {values.status?.value === VIDEO_STATUS.programmed && (
                <Grid container spacing={6} alignItems='flex-end'>
                  <Grid item xs={12} sm={7}>
                    <SingleDatePicker
                      handleOnChange={date => {
                        setFieldValue('publicationDate', date)
                      }}
                      handleOnBlur={handleBlur}
                      minDate={moment().toDate()}
                      value={values.publicationDate}
                      placeholder={i18n.uploadVideoModal.publishDatePlaceholder}
                      label={i18n.uploadVideoModal.publishDate}
                      error={errors.publicationDate && touched.publicationDate && errors.publicationDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <RegularInput
                      handleOnChange={handleChange}
                      handleOnBlur={handleBlur}
                      value={values.time}
                      name='time'
                      type='time'
                      icon={<TimerIcon />}
                      error={errors.time && touched.time && errors.time}
                    />
                  </Grid>
                </Grid>
              )}

              <RegularSelect
                isEmpty
                emptyLabel={i18n.uploadVideoModal.emptyCategory}
                options={categories}
                handleOnChange={category => {
                  setFieldValue('category', category)
                }}
                handleOnBlur={handleBlur}
                keyLabel='nameEs'
                keyValue='id'
                label={i18n.uploadVideoModal.selectCategory}
                defaultValue={{ value: '', label: i18n.uploadVideoModal.optionSelect }}
                error={errors.category && touched.category && errors.category}
              />

              <div className='actions-wrapper'>
                <p className='cancel'>
                  <span onClick={onClose}>{i18n.uploadVideoModal.cancel}</span>
                </p>
                <RegularButton
                  type='submit'
                  loading={isFetching}
                  textUppercase={false}
                  label={i18n.uploadVideoModal.confirm}
                />
              </div>
            </form>
          )}
        </Formik>
      </ComponentStyled>
    </Dialog>
  )
}

UploadVideoModal.defaultProps = {
  isFetching: false,
  categories: [],
}

UploadVideoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  onSubmit: PropTypes.func,
  categories: PropTypes.array,
}

export default UploadVideoModal
