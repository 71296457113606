import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  padding: 15px 0;

  .content {
    text-align: center;
    margin-bottom: 0;
  }

  .content,
  .link {
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.3px;
    color: ${colors.black2};
  }

  .link {
    text-decoration: underline;
  }
`

export default ComponentStyled
