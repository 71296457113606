import React from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'
import Dialog from '@material-ui/core/Dialog'
import { RegularButton } from '../../atoms'
import i18n from '../../../assets/i18n'

const DeleteVideoModal = ({ onClose, open, onDeleteVideo, isFetching }) => {
  return (
    <Dialog classes={{ paper: 'dialog-paper' }} onClose={onClose} aria-labelledby='simple-dialog-title' open={open}>
      <ComponentStyled>
        <p className='title'>{i18n.deleteVideoModal.title}</p>
        <div className='content-wrapper'>
          <p className='content'>{i18n.deleteVideoModal.content}</p>
          <p className='content'>{i18n.deleteVideoModal.content2}</p>
        </div>
        <div className='actions-wrapper'>
          <p className='cancel'>
            <span onClick={onClose}>{i18n.deleteVideoModal.cancel}</span>
          </p>
          <RegularButton
            loading={isFetching}
            onClick={onDeleteVideo}
            textUppercase={false}
            label={i18n.deleteVideoModal.confirm}
          />
        </div>
      </ComponentStyled>
    </Dialog>
  )
}

DeleteVideoModal.defaultProps = {
  isFetching: false,
}

DeleteVideoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onDeleteVideo: PropTypes.func,
  isFetching: PropTypes.bool,
}

export default DeleteVideoModal
