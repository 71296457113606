import _ from 'lodash'
import { APP_SHARE_URL } from '../../../config/constants'

const Redirect = props => {
  const activityId = _.get(props, 'match.params.activityId')
  const username = _.get(props, 'match.params.username')
  if (activityId && username) {
    setTimeout(() => {
      // APP NOT INSTALLED
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      if (/android/i.test(userAgent)) {
        window.location.href = 'https://play.google.com'
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        window.location.href = 'https://www.apple.com/es/ios/app-store/'
      } else {
        window.location.href = 'https://bethey.com'
      }
    }, 500)
    const shareUrl = `${APP_SHARE_URL}?activityId=${activityId}&username=${username}`
    window.location.href = shareUrl
  }
  return null
}

export default Redirect
