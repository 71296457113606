import React, { useEffect, useState } from 'react'
import {
  NATURAL_PERSON_TYPE,
  LEGAL_PERSON_TYPE,
  COUNTRY_CODES_ES,
  PASSPORT,
  NATIONAL_ID,
  COUNTRY_CODES_EN,
  ES_LANG,
} from '../../../config/constants'
import { RegularButton, RegularInput, RegularSelect, SingleDatePicker, FileInput, Spinner } from '../../atoms'
import i18n from '../../../assets/i18n'
import * as API from '../../../api'
import _orderBy from 'lodash/orderBy'
import _find from 'lodash/find'
import _get from 'lodash/get'
import _pickBy from 'lodash/pickBy'
import { Grid } from '@material-ui/core'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import _isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'

const BillingInfoForm = ({ logout }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [isInitFetching, setIsInitFetching] = useState(false)
  const [billingData, setBillingData] = useState({})
  const lang = i18n.getLanguage()
  const countries = lang === ES_LANG ? COUNTRY_CODES_ES : COUNTRY_CODES_EN
  const formatCountryCodes = _orderBy(countries, [user => user.name], ['asc'])
  const personTypeOptions = [
    { value: NATURAL_PERSON_TYPE, label: i18n.billingInfoForm.natural },
    { value: LEGAL_PERSON_TYPE, label: i18n.billingInfoForm.legal },
  ]

  const documentTypeOptions = [
    { value: PASSPORT, label: i18n.billingInfoForm.passport },
    { value: NATIONAL_ID, label: i18n.billingInfoForm.nationalId },
  ]

  useEffect(() => {
    async function getBillingData() {
      try {
        setIsInitFetching(true)
        const response = await API.fetchBillingInfo()
        setBillingData(response)
      } catch (e) {
        const statusCode = _get(e, 'response.status', 1)
        if (statusCode === 403) logout()
        setBillingData({})
      } finally {
        setIsInitFetching(false)
      }
    }

    getBillingData()
  }, [logout])

  const handleSubmitForm = async data => {
    try {
      setIsFetching(true)

      if (data?.photoDocument) {
        const { id } = await API.postDocument({
          file: data?.photoDocument,
          description: i18n.billingInfoForm.photoDocument,
        })

        data.photoDocument = { id }
      }

      if (data?.holdingPhotoDocument) {
        const { id } = await API.postDocument({
          file: data?.holdingPhotoDocument,
          description: i18n.billingInfoForm.holdingPhotoDocument,
        })

        data.holdingPhotoDocument = { id }
      }

      if (data?.bankCertificateDocument) {
        const { id } = await API.postDocument({
          file: data?.bankCertificateDocument,
          description: i18n.billingInfoForm.bankCertificateDocument,
        })
        data.bankCertificateDocument = { id }
      }

      if (data?.companyCertificateDocument) {
        const { id } = await API.postDocument({
          file: data?.companyCertificateDocument,
          description: i18n.billingInfoForm.companyCertificateDocument,
        })
        data.companyCertificateDocument = { id }
      }

      if (_isEmpty(billingData)) {
        data = _pickBy(data, value => value)
        await API.postBillingInfo(data)
      } else {
        await API.updateBillingInfo(data)
      }
      toast.success(i18n.successUpdate)
    } catch (e) {
      const statusCode = _get(e, 'response.status', 1)
      if (statusCode === 403) logout()
      toast.error(i18n.generalError)
    } finally {
      setIsFetching(false)
    }
  }

  if (isInitFetching) {
    return <Spinner alignCenter />
  }

  return (
    <ComponentStyled>
      <Grid container justify='center' spacing={2}>
        <Grid item xs={12} md={5}>
          <Formik
            initialValues={{
              personType: billingData?.personType,
              name: billingData?.name,
              lastName: billingData?.lastName,
              residenceCountry: billingData?.residenceCountry,
              residenceAddress: billingData?.residenceAddress,
              residenceCity: billingData?.residenceCity,
              residencePostalCode: billingData?.residencePostalCode,
              birthDate: billingData?.birthDate,
              documentType: billingData?.documentType,
              documentNumber: billingData?.documentNumber,
              companyName: billingData?.companyName,
              companyEmail: billingData?.companyEmail,
              companyCity: billingData?.companyCity,
              companyAddress: billingData?.companyAddress,
              companyPostalCode: billingData?.companyPostalCode,
              companyCountry: billingData?.companyCountry,
              companyVat: billingData?.companyVat,
              companyIban: billingData?.companyIban,
            }}
            onSubmit={handleSubmitForm}
          >
            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              const findResidenceCountry = _find(formatCountryCodes, { id: values.residenceCountry })
              const findCompanyCountry = _find(formatCountryCodes, { id: values.companyCountry })

              return (
                <form onSubmit={handleSubmit}>
                  <RegularSelect
                    handleOnChange={item => {
                      setFieldValue('personType', item?.value)
                    }}
                    handleOnBlur={handleBlur}
                    defaultValue={_find(personTypeOptions, { value: values.personType })}
                    name='personType'
                    label={i18n.billingInfoForm.personType}
                    options={personTypeOptions}
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.name}
                    name='name'
                    label={i18n.billingInfoForm.name}
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.lastName}
                    name='lastName'
                    label={i18n.billingInfoForm.lastName}
                  />
                  <RegularSelect
                    handleOnChange={item => {
                      setFieldValue('residenceCountry', item?.value)
                    }}
                    handleOnBlur={handleBlur}
                    defaultValue={{
                      value: findResidenceCountry?.id,
                      label: findResidenceCountry?.name,
                    }}
                    name='residenceCountry'
                    label={i18n.billingInfoForm.residenceCountry}
                    options={formatCountryCodes}
                    keyLabel='name'
                    keyValue='id'
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.residenceAddress}
                    name='residenceAddress'
                    label={i18n.billingInfoForm.residenceAddress}
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.residenceCity}
                    name='residenceCity'
                    label={i18n.billingInfoForm.residenceCity}
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.residencePostalCode}
                    name='residencePostalCode'
                    label={i18n.billingInfoForm.residencePostalCode}
                  />
                  <SingleDatePicker
                    handleOnChange={date => {
                      setFieldValue('birthDate', date)
                    }}
                    handleOnBlur={handleBlur}
                    value={values.birthDate}
                    label={i18n.billingInfoForm.birthDate}
                  />

                  <RegularSelect
                    handleOnChange={item => {
                      setFieldValue('documentType', item?.value)
                    }}
                    handleOnBlur={handleBlur}
                    defaultValue={_find(documentTypeOptions, { value: values.documentType })}
                    name='documentType'
                    label={i18n.billingInfoForm.documentType}
                    options={documentTypeOptions}
                  />

                  <FileInput
                    title={i18n.billingInfoForm.photoDocument}
                    label={
                      billingData?.photoDocument
                        ? i18n.billingInfoForm.uploadOtherFile
                        : i18n.billingInfoForm.selectFile
                    }
                    name='photoDocument'
                    handleOnBlur={handleBlur}
                    handleOnChange={photoDocument => {
                      setFieldValue('photoDocument', photoDocument)
                    }}
                    value={values.photoDocument}
                  />

                  <FileInput
                    title={i18n.billingInfoForm.holdingPhotoDocument}
                    label={
                      billingData?.holdingPhotoDocument
                        ? i18n.billingInfoForm.uploadOtherFile
                        : i18n.billingInfoForm.selectFile
                    }
                    handleOnBlur={handleBlur}
                    handleOnChange={holdingPhotoDocument => {
                      setFieldValue('holdingPhotoDocument', holdingPhotoDocument)
                    }}
                    name='holdingPhotoDocument'
                    value={values.holdingPhotoDocument}
                  />

                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.documentNumber}
                    name='documentNumber'
                    label={i18n.billingInfoForm.documentNumber}
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.companyName}
                    name='companyName'
                    label={i18n.billingInfoForm.companyName}
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.companyEmail}
                    name='companyEmail'
                    label={i18n.billingInfoForm.companyEmail}
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.companyCity}
                    name='companyCity'
                    label={i18n.billingInfoForm.companyCity}
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.companyAddress}
                    name='companyAddress'
                    label={i18n.billingInfoForm.companyAddress}
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.companyPostalCode}
                    name='companyPostalCode'
                    label={i18n.billingInfoForm.companyPostalCode}
                  />
                  <RegularSelect
                    handleOnChange={item => {
                      setFieldValue('companyCountry', item?.value)
                    }}
                    handleOnBlur={handleBlur}
                    defaultValue={{
                      value: findCompanyCountry?.id,
                      label: findCompanyCountry?.name,
                    }}
                    name='companyCountry'
                    label={i18n.billingInfoForm.companyCountry}
                    options={formatCountryCodes}
                    keyLabel='name'
                    keyValue='id'
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.companyVat}
                    name='companyVat'
                    label={i18n.billingInfoForm.companyVat}
                  />
                  <RegularInput
                    handleOnChange={handleChange}
                    handleOnBlur={handleBlur}
                    value={values.companyIban}
                    name='companyIban'
                    label={i18n.billingInfoForm.companyIban}
                  />

                  <FileInput
                    title={i18n.billingInfoForm.bankCertificateDocument}
                    label={
                      billingData?.bankCertificateDocument
                        ? i18n.billingInfoForm.uploadOtherFile
                        : i18n.billingInfoForm.selectFile
                    }
                    name='bankCertificateDocument'
                    handleOnBlur={handleBlur}
                    handleOnChange={bankCertificateDocument => {
                      setFieldValue('bankCertificateDocument', bankCertificateDocument)
                    }}
                    value={values.bankCertificateDocument}
                  />
                  <FileInput
                    title={i18n.billingInfoForm.companyCertificateDocument}
                    label={
                      billingData?.companyCertificateDocument
                        ? i18n.billingInfoForm.uploadOtherFile
                        : i18n.billingInfoForm.selectFile
                    }
                    name='companyCertificateDocument'
                    handleOnBlur={handleBlur}
                    handleOnChange={companyCertificateDocument => {
                      setFieldValue('companyCertificateDocument', companyCertificateDocument)
                    }}
                    value={values.companyCertificateDocument}
                  />
                  <div className='save-btn-wrapper'>
                    <RegularButton
                      disable={isFetching}
                      loading={isFetching}
                      type='submit'
                      label={i18n.billingInfoForm.save}
                      variant='big'
                    />
                  </div>
                </form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
    </ComponentStyled>
  )
}

BillingInfoForm.defaultProps = {
  logout: () => {},
}

BillingInfoForm.propTypes = {
  logout: PropTypes.func,
}

export default BillingInfoForm
