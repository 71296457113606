import React, { Component } from 'react'
import ComponentStyled from './styled'
import { LoginForm } from '../../organisms'
import { Container } from '@material-ui/core'
import { Hub } from 'aws-amplify'
import routes from '../../../config/routes'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import { isPublisher } from '../../../utils'
import { toast } from 'react-toastify'
import i18n from '../../../assets/i18n'

class Splash extends Component {
  componentDidMount() {
    Hub.listen('auth', this._onAuthStateListener)
  }

  componentWillUnmount() {
    Hub.remove('auth', this._onAuthStateListener)
  }

  _onAuthStateListener = async ({ payload: { event, data } }) => {
    console.log('Hub.listen event: ', event)
    console.log('Hub.listen data: ', data)
    let token,
      decoded,
      permissions = null

    switch (event) {
      case 'signIn':
        try {
          await this.props.setUser(data)
          token = _.get(data, 'signInUserSession.accessToken.jwtToken', '')
          decoded = jwtDecode(token)
          permissions = _.get(decoded, 'cognito:groups', [])

          if (isPublisher(permissions)) {
            this.props.history.push(routes.dashboard)
          } else {
            toast(i18n.splash.notPublisherError, { type: 'error' })
          }
        } catch {
          toast(i18n.generalError, { type: 'error' })
        }
        break
      case 'signOut':
        break
      case 'customOAuthState':
        console.log('customOAuthState data: ', data)
        break
      default:
        break
    }
  }

  render() {
    return (
      <ComponentStyled>
        <Container maxWidth='lg'>
          <LoginForm />
        </Container>
      </ComponentStyled>
    )
  }
}

Splash.propTypes = {
  setUser: PropTypes.func,
  history: PropTypes.object,
}

export default Splash
