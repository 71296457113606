import React from 'react'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'

const RegularInput = ({
  hasMarginBottom,
  value,
  variant,
  autoComplete,
  placeholder,
  type,
  name,
  handleOnChange,
  handleOnBlur,
  error,
  label,
  icon,
}) => {
  return (
    <ContainerStyled variant={variant} error={error ? true : false} hasMarginBottom={hasMarginBottom}>
      {label && <p className='label'>{label}</p>}
      <div className='regular-input-wrapper'>
        <input
          placeholder={placeholder}
          type={type}
          value={value}
          name={name}
          autoComplete={autoComplete}
          onBlur={handleOnBlur ? handleOnBlur : null}
          onChange={handleOnChange ? e => handleOnChange(e, e.target.value, e.target.name) : null}
        />
        {icon}
      </div>

      {error && <p className='input-error'>{error}</p>}
    </ContainerStyled>
  )
}

RegularInput.defaultProps = {
  type: 'text',
  error: '',
  autoComplete: 'off',
  hasMarginBottom: true,
  capture: false,
}

RegularInput.propTypes = {
  hasMarginBottom: PropTypes.bool,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  error: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  icon: PropTypes.any,
}

export default RegularInput
