import * as types from './types'

const initialState = {
  isFetching: false,
  init: false,
  user: null,
  token: null,
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_AUTH_INIT:
      return { ...state, init: true }
    case types.SET_AUTH_USER:
      return { ...state, user: action.payload }
    case types.SET_AUTH_TOKEN:
      return { ...state, token: action.payload }
    case types.SET_AUTH_FETCHING:
      return { ...state, isFetching: action.payload }
    case types.LOGOUT:
      return { ...initialState, init: state.init }
    default:
      return state
  }
}

export default reducer
