import React from 'react'
import logo from '../../../assets/images/logo.png'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'

const Logo = ({ alignCenter }) => {
  return (
    <ContainerStyled alignCenter={alignCenter}>
      <img src={logo} alt='Bethey' className='logo' />
    </ContainerStyled>
  )
}

Logo.defaultProps = {
  alignCenter: false,
}

Logo.propTypes = {
  alignCenter: PropTypes.bool,
}

export default Logo
