import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  display: flex;

  .tab-item {
    margin: 0 10px;
    border-bottom: 2px solid transparent;
    transition: 0.5s ease all;
    cursor: pointer;
    font-size: 19px;
    color: ${colors.grey};

    &.active {
      color: ${colors.black2};
      border-color: ${colors.red};
    }
  }
`

export default ComponentStyled
