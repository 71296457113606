import axios from 'axios'
import _ from 'lodash'
import * as ApiConfig from '../config/api'
import * as WSLogger from './logger'
import { Auth } from 'aws-amplify'

export const instance = axios.create({
  baseURL: ApiConfig.BASE_URL,
  headers: { 'Content-type': 'application/json' },
})

instance.interceptors.request.use(
  async config => {
    WSLogger.logCall(config.method, config.url, config)
    return config
  },
  err => {
    WSLogger.logError(err)
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  response => {
    WSLogger.logResponse(response.config.method, response.config.url, response)
    return _.get(response, 'data', response)
  },
  async err => {
    WSLogger.logError(err)
    const status = _.get(err, 'response.status', 1)
    const message = _.get(err, 'response.data.message', '')

    if (status !== 403 && message === 'token_expired') {
      try {
        const signInUserSession = await Auth.currentSession()
        const token = _.get(signInUserSession, 'accessToken.jwtToken', null)
        if (token) {
          const originalRequest = err.config
          configureAxiosDefaults('Authorization', `Bearer ${token}`)
          return instance(originalRequest)
        } else {
          return Promise.reject(err)
        }
      } catch (e) {
        return Promise.reject(err)
      }
    } else {
      return Promise.reject(err)
    }
  }
)

export function configureAxiosDefaults(header, value) {
  instance.defaults.headers.common[header] = value
}
export function removeAxiosTokenHeader() {
  delete instance.defaults.headers.common['Authorization']
}

export const refreshToken = () => {
  return Promise.reject(new Error('refreshToken not implemented'))
}
