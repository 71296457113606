export default {
  next: 'Siguiente',
  close: 'Cerrar',
  accept: 'Aceptar',
  yes: 'Sí',
  no: 'No',
  warning: 'Atención',
  continue: 'Continuar',
  requiredField: 'Campo requerido',
  generalError: 'Ha ocurrido un error',
  fileTypeError: 'Vídeos permitidos (.mp4 o .mov)',
  videoDurationError: 'El vídeo no puede ser de más de 1 minuto',
  timeError: 'Hora incorrecta',
  successUpdate: 'Datos actualizados',

  cookieConsent: {
    accept: 'Aceptar todas las cookies',
    change: 'Configurar cookies',
    cookiesPolicyCopy:
      'AGVAR BYTHEY, S.L. utiliza cookies (o tecnologías similares) propias y de tercero para fines analíticos y para mostrarle publicidad personalizada en base a un perfil elaborado a partir de sus hábitos de navegación (por ejemplo, páginas visitadas). Algunas cookies se pueden desactivar haciendo click en "Rechazar cookies no esenciales". A continuación, puede modificar sus elecciones en cualquier momento utilizando el enlace "Política de cookies" en la parte inferior de nuestro sitio web. Para obtener más información, consulte nuestra',
    cookiesPolicy: 'Política de cookies',
  },

  //Atoms
  facebookButton: {
    label: 'Entrar con facebook',
  },

  regularSelect: {
    noResults: 'No hay resultados',
  },

  //Molecules
  tabBar: {
    videos: 'Vídeos',
    billingInfo: 'Datos Facturación',
  },

  //Organisms
  loginForm: {
    email: 'Email',
    password: 'Password',
    recoveryPass: 'Recuperar contraseña',
    signIn: 'Entrar',
    youCanToo: 'También puedes',
  },
  recoveryPassForm: {
    email: 'Email',
    back: 'Volver',
    sendEmail: 'Enviar email',
  },
  videosTable: {
    uploadVideo: 'Subir vídeo',
    name: 'Nombre',
    uploadDate: 'Fecha de subida',
    publishDate: 'Fecha de publicación',
    status: 'Estado',
    actions: 'Acciones',
    public: 'Público',
    private: 'Privado',
    deleted: 'Borrado',
    trailer: 'Trailer',
    error: 'Error',
    programmed: 'Programado',
    noResults: 'No hay resultados',
    rows: 'Resultados',
    nextPage: 'Página siguiente',
    firstPage: 'Primera página',
    lastPage: 'Última página',
    prevPage: 'Página anterior',
  },
  appBar: {
    note: 'Esta web es solo para subida de contenido, para acceder al resto de la plataforma, por favor, visita la app',
  },
  footer: {
    terms: 'Términos y condiciones',
    privacy: 'Política de privacidad',
    cookies: 'Política de cookies',
    legalWarning: 'Aviso legal',
  },
  notFound: {
    title: 'Ha ocurrido un error',
    subtitle: 'No hemos encontrado la página que estaba buscando',
    back: 'Volver al inicio',
  },
  billingInfoForm: {
    passport: 'Pasaporte',
    nationalId: 'Documento de identidad',
    personType: 'Categoría Persona',
    name: 'Nombre',
    lastName: 'Apellidos',
    residenceCountry: 'País',
    residenceAddress: 'Dirección',
    residenceCity: 'Ciudad',
    residencePostalCode: 'Código Postal',
    birthDate: 'Fecha de nacimiento',
    documentType: 'Tipo de documento',
    photoDocument: 'Foto del documento',
    holdingPhotoDocument: 'Foto con el documento',
    documentNumber: 'Nº de documento',
    companyName: 'Nombre de la empresa',
    companyEmail: 'Correo de la empresa',
    companyCity: 'Ciudad de la empresa',
    companyAddress: 'Dirección de la empresa',
    companyPostalCode: 'Código postal de la empresa',
    companyCountry: 'País de la empresa',
    companyVat: 'Nº de identificación de la empresa',
    companyIban: 'IBAN de la empresa',
    bankCertificateDocument: 'Certificado o prueba de la cuenta bancaria relacionada con la empresa donde cobrar',
    companyCertificateDocument: 'Certificado de apoderamiento de la empresa',
    selectFile: 'Arrastra o selecciona el documento',
    uploadOtherFile: 'Subir otro documento',
    natural: 'Física',
    legal: 'Jurídica',
    save: 'Guardar',
  },

  //Pages
  products: {
    title: 'Disfruta de los vídeos exclusivos de las celebrities',
    desc: 'Todos tus ídolos están en Bethey Vídeos totalmente exclusivos No te pierdas nada',
    apply: 'Aplican',
    terms: 'Términos y condiciones',
    note: 'Los créditos de vídeos estarán disponibles en la aplicación de Bethey cuando se efectúe el pago',
    note2:
      'Para tu seguridad, hemos confiado en el sistema de pago mediante tarjeta de crédito o débito a una pasarela de pago segura. Los datos bancarios introducidos son encriptados y transmitidos de forma segura a los servidores de la entidad bancaria y posteriormente, son verificados con el banco emisor para evitar posibles fraudes y abusos.',
    buyVideos: 'Comprar vídeos',
    privacy: 'Política de privacidad',
    legalWarning: 'Aviso legal',
    selectBeneficiary: 'Selecciona un beneficiario',
    beneficiaryTitle:
      'Bethey donará a obras benéficas un porcentaje de las ganancias obtenidas con la visualización de los vídeos durante este mes. Por favor, selecciona un beneficiario.',
    beneficiaryError: 'No hay beneficiarios disponibles',
  },
  productsResult: {
    ready: '¡Listo!',
    desc: 'Hemos añadido {videos} vídeos de crédito a tu cuenta para que los disfrutes ahora mismo.',
    openApp: 'Abrir la aplicación de Bethey',
  },
  splash: {
    notPublisherError: 'No eres creador de contenido',
  },

  //Modals
  deleteVideoModal: {
    title: 'El vídeo va a ser eliminado',
    content: '¿Estás seguro de que quieres continuar?',
    content2: 'Los usuarios que hayan pagado por su visualización dejarán de tener acceso.',
    cancel: 'Cancelar',
    confirm: 'Continuar y eliminar el vídeo',
  },
  updateVideoModal: {
    modalTitle: 'Editar vídeo',
    cancel: 'Cancelar',
    confirm: 'Guardar',
    title: 'Nombre',
    titlePlaceholder: 'Nombre del vídeo',
    publishDate: 'Fecha de publicación',
    publishDatePlaceholder: 'Selecciona la fecha',
    whenPublish: '¿Cuándo quieres publicar?',
    selectCategory: 'Selecciona una categoría',
    public: 'Ahora',
    private: 'Privado',
    programmed: 'En otro momento',
    makePublic: 'Hacer público',
    makePrivate: 'Hacer privado',
    keepPublic: 'Mantener público',
    keepPrivate: 'Mantener privado',
    emptyCategory: 'Ninguna',
  },
  uploadVideoModal: {
    modalTitle: 'Nuevo vídeo',
    cancel: 'Cancelar',
    confirm: 'Subir vídeo',
    title: 'Nombre',
    titlePlaceholder: 'Nombre del vídeo',
    publishDate: 'Fecha de publicación',
    publishDatePlaceholder: 'Selecciona una fecha',
    whenPublish: '¿Cuándo quieres publicar?',
    selectCategory: 'Selecciona una categoría',
    videoPlaceholder: 'Arrastra aquí el vídeo',
    optionSelect: 'Selecciona una opción',
    emptyCategory: 'Ninguna',
  },
}
