import { createGlobalStyle } from 'styled-components'
import colors from '../assets/colors'

export const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-ExtraboldItalic.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-ExtraboldItalic.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-ExtraboldItalic.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-ExtraboldItalic.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-ExtraboldItalic.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-ExtraboldItalic.svg')}) format('svg');
      font-weight: 800;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gilroy';
      src: url(${require('../assets/fonts/gilroy/Gilroy-Heavy.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-Heavy.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Heavy.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-Heavy.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-Heavy.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Heavy.svg')}) format('svg');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-UltraLight.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-UltraLight.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-UltraLight.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-UltraLight.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-UltraLight.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-UltraLight.svg')}) format('svg');
      font-weight: 200;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gilroy';
      src: url(${require('../assets/fonts/gilroy/Gilroy-Black.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-Black.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Black.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-Black.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-Black.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Black.svg')}) format('svg');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-BoldItalic.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-BoldItalic.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-BoldItalic.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-BoldItalic.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-BoldItalic.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-BoldItalic.svg')}) format('svg');
      font-weight: bold;
      font-display: swap;
  }

  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-UltraLightItalic.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-UltraLightItalic.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-UltraLightItalic.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-UltraLightItalic.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-UltraLightItalic.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-UltraLightItalic.svg')}) format('svg');
      font-weight: 200;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gilroy';
      src: url(${require('../assets/fonts/gilroy/Gilroy-Thin.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-Thin.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Thin.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-Thin.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-Thin.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Thin.svg')}) format('svg');
      font-weight: 100;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-LightItalic.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-LightItalic.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-LightItalic.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-LightItalic.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-LightItalic.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-LightItalic.svg')}) format('svg');
      font-weight: 300;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gilroy';
      src: url(${require('../assets/fonts/gilroy/Gilroy-Extrabold.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-Extrabold.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Extrabold.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-Extrabold.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-Extrabold.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Extrabold.svg')}) format('svg');
      font-weight: 800;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-MediumItalic.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-MediumItalic.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-MediumItalic.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-MediumItalic.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-MediumItalic.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-MediumItalic.svg')}) format('svg');
      font-weight: 500;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gilroy';
      src: url(${require('../assets/fonts/gilroy/Gilroy-Medium.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-Medium.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Medium.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-Medium.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-Medium.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Medium.svg')}) format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gilroy';
      src: url(${require('../assets/fonts/gilroy/Gilroy-Semibold.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-Semibold.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Semibold.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-Semibold.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-Semibold.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Semibold.svg')}) format('svg');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gilroy';
      src: url(${require('../assets/fonts/gilroy/Gilroy-Regular.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-Regular.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Regular.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-Regular.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-Regular.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Regular.svg')}) format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-ThinItalic.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-ThinItalic.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-ThinItalic.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-ThinItalic.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-ThinItalic.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-ThinItalic.svg')}) format('svg');
      font-weight: 100;
      font-display: swap;
  }

  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-SemiboldItalic.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-SemiboldItalic.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-SemiboldItalic.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-SemiboldItalic.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-SemiboldItalic.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-SemiboldItalic.svg')}) format('svg');
      font-weight: 600;
      font-display: swap;
  }

  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-RegularItalic.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-RegularItalic.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-RegularItalic.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-RegularItalic.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-RegularItalic.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-RegularItalic.svg')}) format('svg');
      font-weight: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gilroy';
      src: url(${require('../assets/fonts/gilroy/Gilroy-Light.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-Light.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Light.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-Light.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-Light.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Light.svg')}) format('svg');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'Gilroy';
      src: url(${require('../assets/fonts/gilroy/Gilroy-Bold.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-Bold.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Bold.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-Bold.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-Bold.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-Bold.svg')}) format('svg');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
  }

  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-HeavyItalic.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-HeavyItalic.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-HeavyItalic.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-HeavyItalic.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-HeavyItalic.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-HeavyItalic.svg')}) format('svg');
      font-weight: 900;
      font-display: swap;
  }

  @font-face {
      font-family: 'GilroyItalic';
      src: url(${require('../assets/fonts/gilroy/Gilroy-BlackItalic.eot')});
      src: url(${require('../assets/fonts/gilroy/Gilroy-BlackItalic.eot?#iefix')}) format('embedded-opentype'),
          url(${require('../assets/fonts/gilroy/Gilroy-BlackItalic.woff2')}) format('woff2'),
          url(${require('../assets/fonts/gilroy/Gilroy-BlackItalic.woff')}) format('woff'),
          url(${require('../assets/fonts/gilroy/Gilroy-BlackItalic.ttf')}) format('truetype'),
          url(${require('../assets/fonts/gilroy/Gilroy-BlackItalic.svg')}) format('svg');
      font-weight: 900;
      font-display: swap;
  }

  body, *, input, button, label, p, span{
    font-family: Gilroy;
    font-weight: 500;
  }

  p,a,a:hover,li{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.3px;
    color: ${colors.black2};
  }

  ul {
      list-style: initial;
      padding-left: 15px;
      margin-bottom: 15px;

      li {
          margin-bottom: 10px;
      }
  }

  p,h1,h2,h3,h4,h5,h6 {
      margin-bottom: 15px;
  }

  .dialog-paper {
    width: 440px;
  }
`

export const theme = {
  colors,
}

const themeClient = () => {
  return {
    colors,
  }
}

export default themeClient
