import { roles } from '../config/api'
import _ from 'lodash'

export const formatter = new Intl.NumberFormat('es-ES', {
  style: 'currency',
  currency: 'EUR',
})

export const validVideoType = type => {
  const isValid = type === 'video/mp4' || type === 'video/quicktime'
  return isValid
}

export const isPublisher = permissions => {
  const isRolePublisher = _.find(permissions, permission => permission === roles.PUBLISHER)
  return !_.isNil(isRolePublisher)
}
