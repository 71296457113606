import React from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'

const UserAvatar = ({ urlImage, onClick }) => {
  return <ComponentStyled onClick={onClick} urlImage={urlImage}></ComponentStyled>
}

UserAvatar.defaultProps = {
  onClick: () => {},
}

UserAvatar.propTypes = {
  urlImage: PropTypes.string,
  onClick: PropTypes.func,
}

export default UserAvatar
