import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'
import ContainerStyled from './styled'

const Spinner = ({ alignCenter, size }) => (
  <ContainerStyled alignCenter={alignCenter}>
    <CircularProgress color='inherit' size={size} />
  </ContainerStyled>
)

Spinner.defaultProps = {
  alignCenter: false,
  size: 32,
}

Spinner.propTypes = {
  size: PropTypes.number,
  alignCenter: PropTypes.bool,
}

export default Spinner
