import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import { TABS } from '../../../config/constants'
import ComponentStyled from './styled'

const TabBar = ({ activeTab, onClick }) => {
  return (
    <ComponentStyled>
      <span className={`tab-item ${activeTab === TABS.videos && 'active'}`} onClick={() => onClick(TABS.videos)}>
        {i18n.tabBar.videos}
      </span>
      <span
        className={`tab-item ${activeTab === TABS.billingInfo && 'active'}`}
        onClick={() => onClick(TABS.billingInfo)}
      >
        {i18n.tabBar.billingInfo}
      </span>
    </ComponentStyled>
  )
}

TabBar.defaultProps = {
  onClick: () => {},
}

TabBar.propTypes = {
  activeTab: PropTypes.number.isRequired,
  onClick: PropTypes.func,
}

export default TabBar
