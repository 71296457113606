import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  button {
    background: ${colors.red};
    border-radius: 100px;
    padding: 10px 30px;
    cursor: pointer;
    position: relative;

    .loading-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1.15px;
      text-transform: initial;
      color: ${colors.white};
    }

    ${props =>
      props.disable === true &&
      css`
        background: ${colors.grey2};
      `}

    ${props =>
      props.textUppercase === true &&
      css`
        span {
          text-transform: uppercase;
        }
      `}
  }
`

export default ComponentStyled
