import styled from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  min-height: 32px;
  min-width: 32px;
  max-width: 32px;
  display: inline-block;
  background-image: url(${props => props.urlImage});
  background-size: cover;
  background-position: center;
  border-radius: 50px;
  border: 2px solid ${colors.red};
  cursor: pointer;
`

export default ComponentStyled
