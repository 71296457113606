import colors from '../assets/colors'

const theme = {
  colors,
  palette: {
    primary: {
      main: colors.black2,
    },
    secondary: {
      main: colors.grey,
    },
  },
  overrides: {
    MuiTable: {
      root: {
        borderCollapse: 'separate',
        '& .MuiButtonBase-root': {
          borderRadius: '50%',
        },
        '& .MuiButton-contained.Mui-disabled': {
          background: '#1A1A1A',
          color: colors.white,
        },
        '& .MuiIconButton-root': {
          color: colors.red,
          borderRadius: '50%',
        },
        '& .MuiIconButton-root.Mui-disabled': {
          color: colors.redAlpha25,
        },
      },
    },
    MuiTableHead: {
      root: {
        display: 'contents',
      },
    },
    MuiTableBody: {
      root: {},
    },

    MuiTableRow: {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
        borderRadius: '6px',
        '&[index]': {
          border: `0.5px solid ${colors.greyLight}`,
        },
      },
      head: {
        border: 0,
        margin: 0,
      },
      footer: {
        border: '0',
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: 'Gilroy',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.3px',
        color: colors.black2,
        padding: '15px',
        borderBottom: 0,
        alignSelf: 'center',
      },
    },
    MuiPaper: {
      elevation2: {
        boxShadow: 'none',
      },
    },
    MuiTablePagination: {
      root: {
        marginTop: '50px',
      },
    },
  },
}

export default theme
