import styled, { css } from 'styled-components'
import colors from '../../../assets/colors'

const ComponentStyled = styled.div`
  button {
    background: ${colors.blue};
    border-radius: 100px;
    padding: 10px 30px;
    cursor: pointer;
    position: relative;

    ${props =>
      props.disable === true &&
      css`
        background: ${colors.grey2};
      `}

    .loading-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    span {
      display: inline-flex;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1.15px;
      text-transform: uppercase;
      color: ${colors.white};

      .fb-icon {
        margin-right: 10px;
      }
    }
  }
`

export default ComponentStyled
