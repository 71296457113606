export default {
  next: 'Next',
  close: 'Close',
  accept: 'Accept',
  yes: 'Yes',
  no: 'No',
  warning: 'Warning',
  continue: 'Continue',
  requiredField: 'Required field',
  generalError: 'There has been an error',
  fileTypeError: 'Allowed formats (.mp4 o .mov)',
  videoDurationError: 'The video must not exceed 1 minute of duration',
  timeError: 'Wrong time',
  successUpdate: 'Updated data',

  cookieConsent: {
    accept: 'Accept all cookies',
    change: 'Cookies configuration',
    cookiesPolicyCopy:
      'AGVAR BYTHEY, S.L. uses owned and third parties cookies (or related technologies) for analytical purposes and to provide you with personalised publicity based on your browsing habits (ej, visited webpages). Some cookies can be deactivated by clicking ‘Reject non essential cookies’. You can edit your settings at any time by using the link ‘Cookies policy’ at the bottom of this webpage. For further information consult our',
    cookiesPolicy: 'Cookies policy',
  },

  //Atoms
  facebookButton: {
    label: 'Log in through Facebook',
  },

  regularSelect: {
    noResults: 'No results',
  },

  //Molecules
  tabBar: {
    videos: 'Videos',
    billingInfo: 'Billing Information ',
  },

  //Organisms
  loginForm: {
    email: 'Email',
    password: 'Password',
    recoveryPass: 'Recover password',
    signIn: 'Sign in',
    youCanToo: 'You can too',
  },
  recoveryPassForm: {
    email: 'Email',
    back: 'Back',
    sendEmail: 'Send email',
  },
  videosTable: {
    uploadVideo: 'Upload vídeo',
    name: 'Name',
    uploadDate: 'Upload date',
    publishDate: 'Publish date',
    status: 'Status',
    actions: 'Actions',
    public: 'Public',
    private: 'Private',
    deleted: 'Deleted',
    trailer: 'Trailer',
    error: 'Error',
    programmed: 'Programmed',
    noResults: 'No results',
    rows: 'Results',
    nextPage: 'Next page',
    firstPage: 'First page',
    lastPage: 'Last page',
    prevPage: 'Previous page',
  },
  appBar: {
    note: 'This website is for content upload only, to access the rest of the platform, please, visit the app',
  },
  footer: {
    terms: 'Terms and conditions',
    privacy: 'Privacy policy',
    cookies: 'Cookies policy',
    legalWarning: 'Legal notice',
  },
  notFound: {
    title: 'An error has occurred',
    subtitle: 'We have not found the page you were looking for',
    back: 'Back to home',
  },
  billingInfoForm: {
    passport: 'Passport',
    nationalId: 'ID card',
    personType: 'Account type',
    name: 'Name',
    lastName: 'Last name',
    residenceCountry: 'Country',
    residenceAddress: 'Address',
    residenceCity: 'City',
    residencePostalCode: 'Postal code/ZIP',
    birthDate: 'Date of birth',
    documentType: 'Document type',
    photoDocument: 'Photo of your ID',
    holdingPhotoDocument: 'Photo of yourself holding your ID',
    documentNumber: 'Document number',
    companyName: 'Company name',
    companyEmail: 'Company email contact',
    companyCity: 'Company city',
    companyAddress: 'Company address',
    companyPostalCode: 'Company postal code/ZIP',
    companyCountry: 'Company country',
    companyVat: 'VAT Number',
    companyIban: 'IBAN (account number)',
    bankCertificateDocument:
      'Bank certificate linking your company with the bank account where you want to receive the payment',
    companyCertificateDocument: 'Share certificate or a document disclosing your relationship with the company',
    selectFile: 'Choose file or drag here',
    uploadOtherFile: 'Upload other file',
    natural: 'Individual',
    legal: 'Corporation',
    save: 'Save',
  },

  //Pages
  products: {
    title: 'Enjoy exclusive videos from celebrities',
    desc: 'All your idols are on Bethey. Completely exclusive videos. Don’t miss anything',
    apply: 'Apply',
    terms: 'Terms and conditions',
    note: 'Video credits will be available in the Bethey app once the payment is comfirmed',
    note2:
      'For your own protection, we have entrusted all payments through credit or debit card to a secure payment gateway. The bank data will be encrypted and transmitted securely to the servers of the banking entity and will be verified afterwards with the issuing bank in order to avoid any fraud or abuse..',
    buyVideos: 'buy videos',
    privacy: 'Privacy policy',
    legalWarning: 'Legal notice',
    selectBeneficiary: 'Select a beneficiary',
    beneficiaryTitle:
      'Bethey will donate to charities a percentage of the earnings obtained from the viewing of videos during this month. Please, select a beneficiary.',
    beneficiaryError: 'No available beneficiaries',
  },
  productsResult: {
    ready: 'Ready!',
    desc: 'We have added {videos} video credits to your account to be enjoyed right now',
    openApp: 'Open Bethey App',
  },
  splash: {
    notPublisherError: 'You are not a content creator',
  },

  //Modals
  deleteVideoModal: {
    title: 'This video is going to be deleted',
    content: 'Are you sure you want to continue',
    content2: 'Users who have paid for its viewing will cease to have access to it.',
    cancel: 'Cancel',
    confirm: 'Continue and delete video',
  },
  updateVideoModal: {
    modalTitle: 'Edit video',
    cancel: 'Cancel',
    confirm: 'Confirm',
    title: 'Title',
    titlePlaceholder: 'Video title',
    publishDate: 'Publishing date',
    publishDatePlaceholder: 'Select date',
    whenPublish: 'When do you want to publish?',
    selectCategory: 'Select category',
    public: 'Now',
    private: 'Private',
    programmed: 'Later',
    makePublic: 'Update to public',
    makePrivate: 'Update to private',
    keepPublic: 'Keep public',
    keepPrivate: 'Keep private',
    emptyCategory: 'No category',
  },
  uploadVideoModal: {
    modalTitle: 'New video',
    cancel: 'Cancel',
    confirm: 'Upload vídeo',
    title: 'Title',
    titlePlaceholder: 'Video title',
    publishDate: 'Publishing date',
    publishDatePlaceholder: 'Select date',
    whenPublish: 'When do you want to publish?',
    selectCategory: 'Select category',
    videoPlaceholder: 'Drop video',
    optionSelect: 'Select an option',
    emptyCategory: 'No category',
  },
}
