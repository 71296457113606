import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import es from 'date-fns/locale/es'
import i18n from '../../../assets/i18n'
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg'
registerLocale('es', es)

class SingleDatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: props.value ? moment(props.value, 'YYYY-MM-DD').toDate() : null,
    }
  }

  handleChangeStart = date => {
    this.setState({ startDate: date })
    this.props.handleOnChange(date)
  }

  render() {
    const locale = i18n.getLanguage()
    const { error, label, hasMarginBottom, minDate, maxDate, handleOnBlur, placeholder } = this.props

    return (
      <ComponentStyled error={error ? true : false} hasMarginBottom={hasMarginBottom}>
        {label && <p className='label'>{label}</p>}
        <div className='input-wrapper'>
          <DatePicker
            selected={this.state.startDate}
            selectsStart
            minDate={minDate}
            maxDate={maxDate}
            startDate={this.state.startDate}
            onChange={this.handleChangeStart}
            onBlur={handleOnBlur}
            placeholderText={placeholder}
            dateFormat='dd/MM/yyyy'
            locale={locale}
          />
          <CalendarIcon />
        </div>
        {error && <p className='input-error'>{error}</p>}
      </ComponentStyled>
    )
  }
}

SingleDatePicker.defaultProps = {
  placeholder: 'DD/MM/AAAA',
  hasMarginBottom: true,
  handleOnBlur: () => {},
  minDate: null,
  maxDate: null,
  error: '',
}

SingleDatePicker.propTypes = {
  hasMarginBottom: PropTypes.bool,
  label: PropTypes.string,
  styledType: PropTypes.string,
  Icon: PropTypes.element,
  placeholder: PropTypes.string,
  handleOnChangeStart: PropTypes.func,
  resetDate: PropTypes.bool,
  startDateNoFormat: PropTypes.any,
  handleOnChange: PropTypes.func,
  error: PropTypes.string,
  handleOnBlur: PropTypes.func,
  value: PropTypes.any,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
}

export default SingleDatePicker
