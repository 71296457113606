import styled from 'styled-components'

const ComponentStyled = styled.div`
  .page-container {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    .main-wrapper {
      flex: 1;

      .tab-bar-wrapper {
        display: flex;
        justify-content: center;
        margin: 15px 0 30px 0;
      }
    }
  }
`

export default ComponentStyled
