import { connect } from 'react-redux'
import { authOperations } from '../../../redux/auth'
import View from './view'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => dispatch(authOperations.setUser(user)),
    logout: () => dispatch(authOperations.logout()),
  }
}

const enhanced = compose(connect(mapStateToProps, mapDispatchToProps), withRouter)

export default enhanced(View)
