import React, { useState } from 'react'
import { Container } from '@material-ui/core'
import { TABS } from '../../../config/constants'
import { TabBar } from '../../molecules'
import { AppBar, VideosTable, Footer, BillingInfoForm } from '../../organisms'
import ComponentStyled from './styled'

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(TABS.videos)

  return (
    <ComponentStyled>
      <Container className='page-container'>
        <AppBar />
        <div className='main-wrapper'>
          <div className='tab-bar-wrapper'>
            <TabBar activeTab={activeTab} onClick={tab => setActiveTab(tab)} />
          </div>
          {activeTab === TABS.videos ? <VideosTable /> : <BillingInfoForm />}
        </div>
        <Footer />
      </Container>
    </ComponentStyled>
  )
}

export default Dashboard
