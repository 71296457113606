import React from 'react'
import PropTypes from 'prop-types'
import ComponentStyled from './styled'
import Select from 'react-select'
import i18n from '../../../assets/i18n'
import _ from 'lodash'

const RegularSelect = ({
  label,
  isClearable,
  error,
  options,
  handleOnChange,
  handleOnBlur,
  placeholder,
  hasMarginBottom,
  defaultValue,
  keyValue,
  keyLabel,
  isSearchable,
  isEmpty,
  emptyLabel,
}) => {
  const formatOptions = []

  if (isEmpty) {
    formatOptions.push({ value: null, label: emptyLabel })
  }

  _.forEach(options, option => {
    formatOptions.push({ value: _.get(option, keyValue), label: _.get(option, keyLabel) })
  })

  return (
    <ComponentStyled error={error ? true : false} hasMarginBottom={hasMarginBottom}>
      {label && <p className='label'>{label}</p>}
      <Select
        onChange={handleOnChange}
        options={formatOptions}
        onBlur={handleOnBlur}
        noOptionsMessage={() => i18n.regularSelect.noResults}
        isClearable={isClearable}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        className='react-select-container'
        classNamePrefix='react-select'
      />
      {error && <p className='input-error'>{error}</p>}
    </ComponentStyled>
  )
}

RegularSelect.defaultProps = {
  label: '',
  isClearable: false,
  error: '',
  emptyLabel: '-----',
  hasMarginBottom: true,
  placeholder: '',
  options: [],
  handleOnChange: () => {},
  handleOnBlur: () => {},
  keyValue: 'value',
  keyLabel: 'label',
  isSearchable: false,
  isEmpty: false,
}

RegularSelect.propTypes = {
  label: PropTypes.string,
  isClearable: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.array,
  hasMarginBottom: PropTypes.bool,
  placeholder: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  defaultValue: PropTypes.object,
  keyValue: PropTypes.string,
  keyLabel: PropTypes.string,
  isSearchable: PropTypes.bool,
  isEmpty: PropTypes.bool,
  emptyLabel: PropTypes.string,
}

export default RegularSelect
