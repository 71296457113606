import styled from 'styled-components'

const ComponentStyled = styled.div`
  text-align: center;
  padding-top: 30px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .error {
    font-weight: bold;
    font-size: 34px;
    line-height: 34px;
    color: ${({ theme }) => theme.colors.black2};
  }

  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.black2};
    margin-bottom: 10px;
  }

  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: ${({ theme }) => theme.colors.black2};
    margin-bottom: 30px;
  }
`

export default ComponentStyled
