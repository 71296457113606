import styled from 'styled-components'

const ComponentStyled = styled.div`
  .upload-btn-wrapper {
    text-align: right;
    margin-bottom: 30px;
  }

  .edit-icon {
    margin-right: 10px;
  }

  .date {
    font-weight: 300;
  }

  .date,
  .status {
    margin-bottom: 0;
  }

  .delete-icon,
  .edit-icon {
    cursor: pointer;
  }
`

export default ComponentStyled
