export default {
  black: '#000000',
  black2: '#1A1A1A',
  white: '#FFFFFF',
  grey: '#999999',
  grey2: '#ddd3da',
  greyLight: '#e4e4e4',
  darkPurple: '#19011E',
  darkPurple2: '#23002B',
  red: '#F01D22',
  redAlpha25: 'rgba(240, 29, 34, 0.25)',
  blue: '#3B5998',
}
